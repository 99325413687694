import { defineStore } from "pinia";

import { useLocalStorage } from "@vueuse/core";

import { ref, computed } from "@vue/runtime-core";
import ControlService from "@/services/ControlService";
import { PiniaLocalStorageKeys } from "@/utility/PiniaLocalStorageKeys";

const controlService = new ControlService();

export interface InventoryControl {
  inv_lookup_items: Array<InventoryLookupItem>;
  control_id: string;
}

export interface InventoryLookupItem {
  inv_lookup: string;
  inv_lookup_desc: string;
}

export const useInventoryControlStore = defineStore("inventoryControl", () => {
  const data = useLocalStorage(
    PiniaLocalStorageKeys.INV_CONTROL_DATA,
    ref<InventoryControl>({} as InventoryControl),
  );

  const getInventoryLookupItems = computed(() => data.value!.inv_lookup_items);

  async function fetchInventoryControl() {
    if (data.value?.control_id) {
      return data.value;
    }

    const invControl = (await controlService.getControl(
      "INV",
      "INV.CONTROL",
      "CONTROL",
      "",
    )) as InventoryControl[];

    if (invControl && invControl.length > 0) {
      data.value.control_id = invControl[0].control_id || "";
      data.value.inv_lookup_items = invControl[0].inv_lookup_items || [];
    }

    return data.value;
  }

  return {
    getInventoryLookupItems,
    fetchInventoryControl,
  };
});
