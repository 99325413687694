import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-column md:flex-row px-4 pt-4" }
const _hoisted_2 = { class: "flex flex-wrap gap-3" }
const _hoisted_3 = { class: "field-container" }
const _hoisted_4 = { class: "field-container" }
const _hoisted_5 = { class: "field-container" }
const _hoisted_6 = { class: "field-container" }
const _hoisted_7 = { class: "field-container" }
const _hoisted_8 = { class: "field-container" }
const _hoisted_9 = { class: "flex-1" }
const _hoisted_10 = { class: "customer-tab" }
const _hoisted_11 = { class: "line-height-4 pr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartsDataForm = _resolveComponent("PartsDataForm")!
  const _component_Card = _resolveComponent("Card")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_CommitmentTable = _resolveComponent("CommitmentTable")!
  const _component_ShortsTable = _resolveComponent("ShortsTable")!
  const _component_OrdersTable = _resolveComponent("OrdersTable")!
  const _component_LotTable = _resolveComponent("LotTable")!
  const _component_UsageChart = _resolveComponent("UsageChart")!
  const _component_AllocationsTable = _resolveComponent("AllocationsTable")!
  const _component_Details = _resolveComponent("Details")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransactionsTable = _resolveComponent("TransactionsTable")!
  const _component_LookupDataTable = _resolveComponent("LookupDataTable")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TabView, {
      activeIndex: 0,
      scrollable: true,
      class: "account-tabview",
      lazy: ""
    }, {
      default: _withCtx(() => [
        (_ctx.getActivePart?.record)
          ? (_openBlock(), _createBlock(_component_TabPanel, { key: 0 }, {
              header: _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("div", { class: "customer-tab" }, [
                  _createElementVNode("span", { class: "line-height-4 pr-2" }, "Part")
                ], -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_Card, null, {
                  content: _withCtx(() => [
                    _createVNode(_component_PartsDataForm, {
                      part: _ctx.getActivePart!.record,
                      allowSave: _ctx.allowSave
                    }, null, 8, ["part", "allowSave"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_TabPanel, null, {
          header: _withCtx(() => _cache[11] || (_cache[11] = [
            _createElementVNode("div", { class: "customer-tab" }, [
              _createElementVNode("span", { class: "line-height-4 pr-2" }, "Inventory")
            ], -1)
          ])),
          default: _withCtx(() => [
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                  key: 0,
                  class: "col-12 justify-content-center",
                  style: {"display":"flex"}
                }))
              : (!_ctx.getActivePart?.hasInventoryRecord)
                ? (_openBlock(), _createBlock(_component_Card, { key: 1 }, {
                    content: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createElementVNode("div", { class: "flex justify-content-center" }, [
                        _createElementVNode("span", { class: "text-center" }, "Part does not have an inventory record.")
                      ], -1)
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_Card, { key: 2 }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_InputText, {
                              value: _ctx.partsId,
                              placeholder: "Part ID",
                              disabled: ""
                            }, null, 8, ["value"])
                          ]),
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_Button, {
                              icon: "pi pi-replay",
                              label: "Refresh",
                              onClick: _ctx.handleRefresh
                            }, null, 8, ["onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.stdcost,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stdcost) = $event)),
                              placeholder: "Std Cost",
                              disabled: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.um,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.um) = $event)),
                              placeholder: "U/M",
                              disabled: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.average_cost,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.average_cost) = $event)),
                              placeholder: "Average Cost",
                              disabled: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.mo_usage,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.mo_usage) = $event)),
                              placeholder: "Mo Usage",
                              disabled: ""
                            }, null, 8, ["modelValue"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_Textarea, {
                            modelValue: _ctx.part_description,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.part_description) = $event)),
                            class: "field-container",
                            autoResize: "",
                            rows: "5",
                            cols: "30",
                            placeholder: "Description",
                            disabled: ""
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createVNode(_component_DataTable, {
                        sortOrder: 1,
                        value: _ctx.invloc_items,
                        class: "p-datatable-sm w-12 text-sm bordered-datatable",
                        stripedRows: "",
                        responsiveLayout: "stack",
                        breakpoint: "767px",
                        scrollable: "",
                        scrollHeight: "100%",
                        style: {"height":"100%","overflow":"auto"}
                      }, {
                        empty: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createElementVNode("div", { class: "flex justify-content-center" }, [
                            _createElementVNode("span", { class: "text-center" }, "No Inventory Found")
                          ], -1)
                        ])),
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            field: "invloc",
                            header: "Invloc",
                            "header-class": "column-header-left"
                          }),
                          _createVNode(_component_Column, {
                            field: "group",
                            header: "Group",
                            "header-class": "column-header-left"
                          }),
                          _createVNode(_component_Column, {
                            field: "on_hand",
                            header: "On Hand",
                            "header-class": "column-header-right",
                            "body-style": "text-align: right"
                          }),
                          _createVNode(_component_Column, {
                            field: "commited",
                            header: "Commited",
                            "header-class": "column-header-right",
                            "body-style": "text-align: right"
                          }),
                          _createVNode(_component_Column, {
                            field: "allocated",
                            header: "Allocated",
                            "header-class": "column-header-right",
                            "body-style": "text-align: right"
                          }),
                          _createVNode(_component_Column, {
                            field: "shortages",
                            header: "Shortages",
                            "header-class": "column-header-right",
                            "body-style": "text-align: right"
                          }),
                          _createVNode(_component_Column, {
                            field: "available",
                            header: "Available",
                            "header-class": "column-header-right",
                            "body-style": "text-align: right"
                          }),
                          _createVNode(_component_Column, {
                            field: "on_order",
                            header: "On Order",
                            "header-class": "column-header-right",
                            "body-style": "text-align: right"
                          }),
                          _createVNode(_component_Column, {
                            header: "",
                            style: {"width":"1rem"},
                            "body-style": "padding: 4px; margin: 4px;"
                          }, {
                            body: _withCtx((data) => [
                              (_ctx.hasBins(data.data))
                                ? (_openBlock(), _createBlock(_component_Button, {
                                    key: 0,
                                    onClick: ($event: any) => (_ctx.handleRowClick(data)),
                                    label: "Bins",
                                    class: "p-button-sm",
                                    style: {"padding":"8px"}
                                  }, null, 8, ["onClick"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ]),
                    _: 1
                  }))
          ]),
          _: 1
        }),
        (_ctx.showInventoryInfo)
          ? (_openBlock(), _createBlock(_component_TabPanel, { key: 1 }, {
              header: _withCtx(() => _cache[14] || (_cache[14] = [
                _createElementVNode("div", { class: "customer-tab" }, [
                  _createElementVNode("span", { class: "line-height-4 pr-2" }, "Commitments")
                ], -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_Card, null, {
                  content: _withCtx(() => [
                    _createVNode(_component_CommitmentTable, { partNumber: _ctx.partsId }, null, 8, ["partNumber"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showInventoryInfo)
          ? (_openBlock(), _createBlock(_component_TabPanel, { key: 2 }, {
              header: _withCtx(() => _cache[15] || (_cache[15] = [
                _createElementVNode("div", { class: "customer-tab" }, [
                  _createElementVNode("span", { class: "line-height-4 pr-2" }, "Shortages")
                ], -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_Card, null, {
                  content: _withCtx(() => [
                    _createVNode(_component_ShortsTable, { partNumber: _ctx.partsId }, null, 8, ["partNumber"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showInventoryInfo)
          ? (_openBlock(), _createBlock(_component_TabPanel, { key: 3 }, {
              header: _withCtx(() => _cache[16] || (_cache[16] = [
                _createElementVNode("div", { class: "customer-tab" }, [
                  _createElementVNode("span", { class: "line-height-4 pr-2" }, "On Order")
                ], -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_Card, null, {
                  content: _withCtx(() => [
                    _createVNode(_component_OrdersTable, { partNumber: _ctx.partsId }, null, 8, ["partNumber"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showInventoryInfo)
          ? (_openBlock(), _createBlock(_component_TabPanel, { key: 4 }, {
              header: _withCtx(() => _cache[17] || (_cache[17] = [
                _createElementVNode("div", { class: "customer-tab" }, [
                  _createElementVNode("span", { class: "line-height-4 pr-2" }, "Lot Information")
                ], -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_Card, null, {
                  content: _withCtx(() => [
                    _createVNode(_component_LotTable, { partNumber: _ctx.partsId }, null, 8, ["partNumber"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showInventoryInfo)
          ? (_openBlock(), _createBlock(_component_TabPanel, { key: 5 }, {
              header: _withCtx(() => _cache[18] || (_cache[18] = [
                _createElementVNode("div", { class: "customer-tab" }, [
                  _createElementVNode("span", { class: "line-height-4 pr-2" }, "Usage History")
                ], -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_UsageChart, { partNumber: _ctx.partsId }, null, 8, ["partNumber"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showInventoryInfo)
          ? (_openBlock(), _createBlock(_component_TabPanel, { key: 6 }, {
              header: _withCtx(() => _cache[19] || (_cache[19] = [
                _createElementVNode("div", { class: "customer-tab" }, [
                  _createElementVNode("span", { class: "line-height-4 pr-2" }, "Allocations")
                ], -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_Card, null, {
                  content: _withCtx(() => [
                    _createVNode(_component_AllocationsTable, { partNumber: _ctx.partsId }, null, 8, ["partNumber"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showInventoryInfo)
          ? (_openBlock(), _createBlock(_component_TabPanel, { key: 7 }, {
              header: _withCtx(() => _cache[20] || (_cache[20] = [
                _createElementVNode("div", { class: "customer-tab" }, [
                  _createElementVNode("span", { class: "line-height-4 pr-2" }, "Transactions")
                ], -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_Dialog, {
                  header: "Transfer",
                  visible: _ctx.showTransferDialog,
                  "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showTransferDialog) = $event)),
                  onHide: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showTransferDialog = false)),
                  modal: "",
                  style: {"max-width":"600px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Details, { part_no: _ctx.partsId }, null, 8, ["part_no"])
                  ]),
                  _: 1
                }, 8, ["visible"]),
                _createVNode(_component_Card, null, {
                  content: _withCtx(() => [
                    _createVNode(_component_TransactionsTable, {
                      partNumber: _ctx.partsId,
                      onAddTransaction: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showTransferDialog = true))
                    }, null, 8, ["partNumber"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLookups, (lookup, index) => {
          return (_openBlock(), _createBlock(_component_TabPanel, { key: index }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, _toDisplayString(lookup.title), 1)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_Card, null, {
                content: _withCtx(() => [
                  _createVNode(_component_LookupDataTable, {
                    lookup: lookup,
                    params: _ctx.getLookupParameters,
                    class: "p-datatable-sm w-12 text-sm bordered-datatable"
                  }, null, 8, ["lookup", "params"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_Dialog, {
      header: _ctx.selectedInv.invloc + ' Bins',
      visible: _ctx.showInvDialog,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showInvDialog) = $event)),
      onHide: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showInvDialog = false)),
      modal: ""
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_DataTable, {
          value: _ctx.selectedInv.bin_items,
          class: "p-datatable-sm w-12 text-sm bordered-datatable",
          stripedRows: "",
          responsiveLayout: "stack",
          breakpoint: "767px",
          scrollable: "",
          scrollHeight: "100%",
          rowGroupMode: "rowspan",
          groupRowsBy: "invloc",
          style: {"height":"100%","overflow":"auto"}
        }, {
          header: _withCtx(() => _cache[21] || (_cache[21] = [
            _createTextVNode(" Bins ")
          ])),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "bin",
              header: "Bin",
              sortable: ""
            }),
            _createVNode(_component_Column, {
              field: "bin_qty",
              header: "Quantity",
              sortable: ""
            }),
            _createVNode(_component_Column, {
              field: "bin_allocated",
              header: "Allocated",
              sortable: ""
            })
          ]),
          _: 1
        }, 8, ["value"]), [
          [_vShow, _ctx.selectedInv.bin_items?.length]
        ]),
        _withDirectives(_createVNode(_component_DataTable, {
          value: _ctx.selectedInv.bin_loc_items,
          class: "p-datatable-sm w-12 text-sm bordered-datatable",
          stripedRows: "",
          responsiveLayout: "stack",
          breakpoint: "767px",
          scrollable: "",
          scrollHeight: "100%",
          rowGroupMode: "rowspan",
          groupRowsBy: "invloc",
          style: {"height":"100%","overflow":"auto"}
        }, {
          header: _withCtx(() => _cache[22] || (_cache[22] = [
            _createTextVNode(" Reference Bins ")
          ])),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "bin_loc",
              header: "Bin",
              sortable: ""
            })
          ]),
          _: 1
        }, 8, ["value"]), [
          [_vShow, _ctx.selectedInv.bin_loc_items?.length]
        ])
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ], 64))
}