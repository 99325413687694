export const FDICT_PARTS = {
  PART_NO: "0",
  DESCRIPTION: "1",
  TYPE: "2",
  UM: "3",
  SPECS: "4",
  NOTES: "5",
  MFG_NAME: "6",
  MFG_PART: "7",
  REV: "8",
  ABC_CODE: "9",
  SAFETY_STOCK: "10",
  ORDER_QTY: "11",
  LEAD_TIME: "12",
  PRODCON_ID: "13",
  FRACTIONS: "14",
  STATUS: "15",
  MRP_PART: "16",
  MIN_MAX_PART: "17",
  PICKLIST_LABELS: "18",
  MAX_STOCK: "19",
  VENDOR: "20",
  LOT_CONTROL: "21",
  SHELF_LIFE: "22",
  BUYER: "23",
  PLANNER: "24",
  REV_HISTORY: "25",
  REV_DESC: "26",
  REV_ECN: "27",
  REV_DATE: "28",
  DRAWING_NUMBER: "29",
  BUY_UM: "30",
  FACTOR: "31",
  WEIGHT: "32",
  INVLOC: "33",
  CONFIG_GROUP: "34",
  CONFIG_OPTION: "35",
  CONFIG_NOTES: "36",
  CONFIG_DESC: "37",
  CONFIG_ID: "38",
  MRK_CODE: "39",
  MODEL: "41",
  CATEGORY: "42",
  LOW_LEVEL_CODE: "43",
  TPOP: "44",
  ROUTING: "45",
  PEGGING: "46",
  ORDER_POLICY: "47",
  ORDER_MULTIPLE: "48",
  ORDER_MAXIMUM: "49",
  SCRAP_FACTOR: "50",
  MAKE_BUY: "52",
  MPS_PART: "53",
  MPS_LOW_LEVEL: "54",
  PLAN_GROUP: "55",
  WIP_LOC: "56",
  RI_LOC: "57",
  STAMP_DATE: "58",
  STAMP_USER: "59",
  TAXABLE: "60",
  DESC_WORDS: "61",
  STOCK_LOC: "62",
  LSTOCK_LOC: "63",
  UPC: "64",
  CREATE_WO: "66",
  IMAGE_DESC: "67",
  IMAGE_PATH: "68",
  FET: "69",
  WEB_CATEGORY: "70",
  WEB_DESCRIPTION: "71",
  WEB_INFORMATION: "72",
  WEB_IMAGE_PATH: "73",
  CUST: "74",
  CUST_PART: "75",
  EQUIV_PART: "76",
  SUPERCEDE_FLAG: "77",
  SALES_HOLD: "78",
  SERIAL_REQUIRED: "79",
  BOM_UM: "101",
  BOM_UM_FACTOR: "102",
  NO_CAT_DISC: "103",
  MRP_DECIMALS: "104",
  COUNTRY_ORIGIN: "105",
  MAKE_HOLD: "106",
  BUY_HOLD: "107",
  WRAP_DESC: "108",
  WEB_CUSTOMER: "109",
  WEB_FEATURED: "110",
  ROHS: "111",
  DATE_CREATED: "112",
  WEB_NO_BUY: "113",
  WEB_PRICE_TEXT: "114",
  CHANGE_DATE: "115",
  CHANGE_TIME: "116",
  CHANGED_BY: "117",
  APPROVED_BY: "118",
  CHANGE_TEXT: "119",
  CHANGE_NOTES: "120",
  EXP_REGULATION: "121",
  BIS_LICENSE: "122",
  HTS_SCHEDB: "123",
  SHIP_LENGTH: "124",
  SHIP_WIDTH: "125",
  SHIP_HEIGHT: "126",
  SHIP_SEPARATE: "127",
  INSP_STEP: "128",
  INSP_INSTRUCT: "129",
  SAMPLE_PCT: "130",
  ATTACHMENTS: "131",
  ATTACHMENT_DESC: "132",
  SHIP_QTY: "133",
  CONFIG_CODE: "134",
  AVATAX_CODE: "135",
  MIVA_PRODUCT_ID: "136",
  MIVA_IMAGES: "137",
  ALT_PART_NO: "140",
};
