
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { mapActions, mapState } from "pinia";
import InputText from "primevue/inputtext";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressSpinner from "primevue/progressspinner";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import Tooltip from "primevue/tooltip";
import CommitmentTable from "./CommitmentTable.vue";
import AllocationsTable from "./AllocationsTable.vue";
import TransactionsTable from "./TransactionsTable.vue";
import OrdersTable from "./OrdersTable.vue";
import ShortsTable from "./ShortsTable.vue";
import { useInventoryStore } from "@/store/ui/inventoryModule";
import LotTable from "./LotTable.vue";
import LookupDataTable from "../Sales/LineItems/LookupDataTable.vue";
import Lookup from "@/types/lookup";
import { useInventoryControlStore } from "@/store/data/controls/inv";
import { InvLoc } from "@/types/state/inventory";
import PartsDataForm from "./PartsDataForm.vue";
import Dialog from "primevue/dialog";
import UsageChart from "./UsageChart.vue";
import Details from "../Scan/Transfer/Details.vue";
import Card from "primevue/card";
import { AccessRights } from "@/types/accessControl/AbstractAccessGetter";
export default defineComponent({
  name: "PartsForm",
  props: {
    partsId: {
      type: String,
      required: true,
    },
  },
  components: {
    Button,
    InputText,
    Textarea,
    TabView,
    TabPanel,
    DataTable,
    Column,
    ProgressSpinner,
    CommitmentTable,
    AllocationsTable,
    TransactionsTable,
    OrdersTable,
    LotTable,
    ShortsTable,
    LookupDataTable,
    PartsDataForm,
    Dialog,
    UsageChart,
    Details,
    Card,
  },
  inject: ["companyCode", "getAccessRights"],
  directives: {
    tooltip: Tooltip,
  },
  methods: {
    ...mapActions(useInventoryStore, ["fetchInventoryById", "resetPartById"]),
    handleRefresh() {
      this.resetPartById(this.partsId);
      this.getInventory(this.partsId, this.client, "um average_cost");
    },
    async getInventory(partId: string, client: string, correls = "") {
      this.isLoading = true;
      const resp = await this.fetchInventoryById(partId, client, correls);
      if (resp.success && resp.inv) {
        this.um = resp.inv.um || "";
        this.average_cost = resp.inv.average_cost || "";
        this.invloc_items = resp.inv.invloc_items || [];
      } else {
        this.um = "";
        this.average_cost = "";
        this.invloc_items = [];
      }

      this.isLoading = false;
    },
    handleRowClick(event: any) {
      const inv = this.invloc_items.find(
        (inv) => inv.invloc == event.data.invloc,
      );
      if (inv) {
        this.showInvDialog = this.hasBins(inv);
        this.selectedInv = inv;
      }
    },
    hasBins(inv: InvLoc): boolean {
      return (
        (inv.bin_items || []).length > 0 || (inv.bin_loc_items || []).length > 0
      );
    },
  },
  computed: {
    ...mapGetters({
      client: "session/getClient",
    }),
    ...mapState(useInventoryControlStore, {
      getInventoryLookupItems: "getInventoryLookupItems",
    }),
    ...mapState(useInventoryStore, {
      getActivePart: "getActivePart",
    }),
    showInventoryInfo(): boolean {
      return this.getActivePart?.old_record != undefined;
    },
    getLookupParameters(): any {
      return {
        coCode: this.companyCode,
        parts: this.partsId,
      };
    },
    getInvLookups(): any[] {
      return this.getInventoryLookupItems.map((lookup) => {
        return {
          key: lookup.inv_lookup,
          title: lookup.inv_lookup_desc,
        };
      });
    },
    getLookups(): Lookup[] {
      if (!this.showInventoryInfo) {
        return [];
      }

      return this.getInvLookups
        .filter((lookup: Lookup) => lookup.key)
        .map((lookup: Lookup) => {
          const splitLookup = lookup.key.split("*");
          return {
            ...lookup,
            lookupName: splitLookup[1],
            file: splitLookup[0],
            recordId: this.partsId,
          };
        });
    },
    allowSave(): boolean {
      return (this.getAccessRights as AccessRights).change;
    },
  },
  mounted() {
    this.getInventory(this.partsId, this.client, "um average_cost");
  },
  data() {
    return {
      isLoading: false,
      searchPartNumber: "",
      stdcost: "",
      um: "",
      average_cost: "",
      mo_usage: "",
      part_description: "",
      load: "",
      date_created: "",
      invloc_items: [] as InvLoc[],
      selectedInv: {} as InvLoc,
      showInvDialog: false,
      showTransferDialog: false,
    };
  },
  watch: {
    partsId() {
      this.getInventory(this.partsId, this.client, "um average_cost");
    },
  },
});
