
import { defineComponent } from "vue";
import { mapGetters, useStore } from "vuex";

import Part from "@/types/part";
import RoverInput from "../UI/RoverInput.vue";
import { Field } from "@/types/fdict";
import CollapsibleSection from "../UI/CollapsibleSection.vue";
import { AccessRights } from "@/types/accessControl/AbstractAccessGetter";
import Image from "primevue/image";
import Textarea from "primevue/textarea";
import Carousel from "primevue/carousel";
import { mapActions, mapState } from "pinia";
import { useInventoryStore } from "@/store/ui/inventoryModule";
import RoverGrid from "../UI/RoverGrid.vue";
import { useNotifications } from "@/composables/Notification/useNotifications";
import SplitButton from "primevue/splitbutton";
import { FDICT_PARTS } from "@/utility/fdicts/parts";
export default defineComponent({
  props: {
    part: {
      required: true,
    },
    allowSave: {
      default: false,
      type: Boolean,
    },
  },
  inject: ["getAccessRights"],
  components: {
    RoverInput,
    RoverGrid,
    CollapsibleSection,
    Carousel,
    Textarea,
    Image,
    SplitButton,
  },
  setup() {
    const store = useStore();
    const {
      addSuccessNotification,
      addWarnNotification,
      addErrorNotification,
    } = useNotifications(store);
    return {
      addSuccessNotification,
      addWarnNotification,
      addErrorNotification,
    };
  },
  computed: {
    ...mapGetters({
      getFdict: "fdict/getFdict",
      getField: "fdict/getField",
      getFields: "fdict/getFields",
    }),
    ...mapState(useInventoryStore, {
      getActivePart: "getActivePart",
    }),
    generalFields(): Field[] {
      const fields = [
        FDICT_PARTS.UM,
        FDICT_PARTS.DATE_CREATED,
        FDICT_PARTS.CATEGORY,
        FDICT_PARTS.MODEL,
        FDICT_PARTS.CONFIG_ID,
        FDICT_PARTS.UPC,
        FDICT_PARTS.TYPE,
        FDICT_PARTS.STATUS,
      ];
      return this.getFields("PARTS", fields);
    },
    idField(): Field {
      return this.getField("PARTS", FDICT_PARTS.PART_NO);
    },
    disableEdits(): boolean {
      return !(this.getAccessRights as AccessRights).change;
    },
    imageFields(): Field[] {
      const fields = [FDICT_PARTS.IMAGE_DESC, FDICT_PARTS.IMAGE_PATH];
      return this.getFields("PARTS", fields);
    },
    getDrawingNumberFields(): Field[] {
      const fields = [FDICT_PARTS.DRAWING_NUMBER];
      return this.getFields("PARTS", fields);
    },
    getEquivPartFields(): Field[] {
      const fields = [FDICT_PARTS.EQUIV_PART, FDICT_PARTS.SUPERCEDE_FLAG];
      return this.getFields("PARTS", fields);
    },
    getAltPartNoFields(): Field[] {
      const fields = [FDICT_PARTS.ALT_PART_NO];
      return this.getFields("PARTS", fields);
    },
    getManufacturerNameFields(): Field[] {
      const fields = [FDICT_PARTS.MFG_NAME, FDICT_PARTS.MFG_PART];
      return this.getFields("PARTS", fields);
    },
    getCustItemFields(): Field[] {
      const fields = [FDICT_PARTS.CUST, FDICT_PARTS.CUST_PART];
      return this.getFields("PARTS", fields);
    },
    getVendorNumberFields(): Field[] {
      const fields = [FDICT_PARTS.VENDOR];
      return this.getFields("PARTS", fields);
    },
    materialControlFields(): Field[] {
      const fields = [
        FDICT_PARTS.LOT_CONTROL,
        FDICT_PARTS.PICKLIST_LABELS,
        FDICT_PARTS.TAXABLE,
        FDICT_PARTS.SERIAL_REQUIRED,
        FDICT_PARTS.SALES_HOLD,
        FDICT_PARTS.SHELF_LIFE,
        FDICT_PARTS.FET,
        FDICT_PARTS.NO_CAT_DISC,
        FDICT_PARTS.MAKE_HOLD,
        FDICT_PARTS.BUY_UM,
        FDICT_PARTS.FACTOR,
        FDICT_PARTS.BOM_UM,
        FDICT_PARTS.BOM_UM_FACTOR,
        FDICT_PARTS.AVATAX_CODE,
        FDICT_PARTS.BUY_HOLD,
        FDICT_PARTS.NO_CAT_DISC,
        FDICT_PARTS.ROHS,
        FDICT_PARTS.LOW_LEVEL_CODE,
        FDICT_PARTS.ABC_CODE,
      ];
      return this.getFields("PARTS", fields);
    },
    planningInformationFields(): Field[] {
      const fields = [
        FDICT_PARTS.PLAN_GROUP,
        FDICT_PARTS.BUYER,
        FDICT_PARTS.PLANNER,
        FDICT_PARTS.INVLOC,
        FDICT_PARTS.WIP_LOC,
        FDICT_PARTS.RI_LOC,
        FDICT_PARTS.STOCK_LOC,
        FDICT_PARTS.LSTOCK_LOC,
        FDICT_PARTS.MAKE_BUY,
        FDICT_PARTS.MRP_PART,
        FDICT_PARTS.MIN_MAX_PART,
        FDICT_PARTS.MPS_PART,
        FDICT_PARTS.ROUTING,
        FDICT_PARTS.PEGGING,
        FDICT_PARTS.ORDER_QTY,
        FDICT_PARTS.ORDER_MULTIPLE,
        FDICT_PARTS.ORDER_MAXIMUM,
        FDICT_PARTS.MRP_DECIMALS,
        FDICT_PARTS.SAFETY_STOCK,
        FDICT_PARTS.MAX_STOCK,
        FDICT_PARTS.SCRAP_FACTOR,
        FDICT_PARTS.LEAD_TIME,
        FDICT_PARTS.TPOP,
      ];
      return this.getFields("PARTS", fields);
    },
    splitButtonItems() {
      return [
        // {
        //   label: "Undo Changes",
        //   icon: "pi pi-refresh",
        //   command: () => {
        //     this.undoPartChanges();
        //   },
        // },
        {
          label: "Refresh",
          icon: "pi pi-refresh",
          command: () => {
            this.refreshPart();
          },
        },
      ] as any;
    },
    saveButtonIcon(): string {
      return this.loading ? "pi pi-spin pi-spinner" : "pi pi-save";
    },
    showPlanGroup(): boolean {
      return (this.partInput?.plan_group_items &&
        this.partInput.plan_group_items.length > 0) as boolean;
    },
  },
  methods: {
    ...mapActions(useInventoryStore, [
      "savePartRecord",
      "restorePartRecord",
      "fetchPartById",
    ]),
    undoPartChanges() {
      const id = this.getActivePart!.id;
      this.restorePartRecord(id);
    },
    async refreshPart() {
      this.loading = true;
      if (this.getActivePart?.old_record?.part_no) {
        const resp = await this.fetchPartById(
          this.getActivePart!.old_record.part_no,
          true,
        );
        if (resp.success) {
          this.addSuccessNotification("Part refreshed successfully");
        }
      }
      this.loading = false;
    },
    async savePart() {
      this.loading = true;
      const id = this.getActivePart!.id;
      const resp = await this.savePartRecord(id);
      if (resp.success) {
        this.addSuccessNotification("Part saved successfully");
      }
      this.loading = false;
    },
  },
  mounted() {
    this.partInput = this.part as Part;
  },
  updated() {
    this.partInput = this.part as Part;
  },
  data() {
    return {
      partInput: {} as Part,
      sections: {
        general: true,
        images: false,
        material_control: false,
        customer_supplier: false,
      },
      loading: false,
    };
  },
});
